.ubf-accordion {
	position: relative;

	&__content {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $gridGutter, $hSpacePos: left, $vSpacePos: bottom);
		left: 0;
		padding: $base 0 0 $base;
		position: relative;
		right: 0;
		top: 0;

		// &.ubf-js-collapsed {
		// 	opacity: 0;
		// 	position: absolute;

		// 	html.no-js &:focus-within {
		// 		opacity: 1;
		// 		position: relative;
		// 	}
		// }
	}


	&__contentWrapper {
		margin: 0 0 $base 0;
		overflow: hidden;
		position: relative;
		width: 100%;

		.ubf-accordion--boxed & {
			@include ubf-highlightColor(border-left-color);
			border-left-style: solid;
			border-left-width: $boxBorderSize;
		}
	}


	&__toggler {
		width: 100%;
	}


	&__togglerIcon {
		@include ease(transform, $duration4);
		display: inline-block;
		height: $base;
		line-height: 0;
		margin-right: $base * 0.5;
		order: 1;
		position: relative;
		width: auto;
		transform: rotate(90deg);

		.ubf-accordion--boxed & {
			margin-left: $base * 0.5;
			margin-right: 0;
			order: 2;
			// transform: rotate(270deg);
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__togglerLabel {
		display: inline-block;
		order: 2;

		.ubf-accordion--boxed & {
			order: 1;
		}
	}


	&__togglerLink {
		@include ubf-highlightColor(border-color);
		background-color: transparent;
		align-items: center;
		color: $colorBase;
		display: flex;
		flex-direction: row;
		font-weight: bold;
		justify-content: flex-start;
		height: 100%;
		// padding: $baseHalf $base;
		position: relative;
		text-decoration: none;


		.ubf-accordion--boxed & {
			@include ease(background-color border-color, $duration4);
			border-color: transparent;
			border-style: solid;
			border-width: 0 0 0 $boxBorderSize;
			background-color: $colorBgTabs;
			color: $colorTabs;
			justify-content: space-between;
			padding: $baseHalf $base;
		}

		// &.ubf-js-toggled {
		// }
	}
}
