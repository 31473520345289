@import "common/functions/px-to-rem";
@import "common/functions/rem-to-px";
@import "common/functions/get-font-size";
@import "common/functions/get-line-height";

// config
@import "ubf-print/config/config";
@import "ubf/config/font-faces";


// common functions
@import "common/functions/dynamic-column-push";
@import "common/functions/dynamic-column-size";
@import "common/functions/static-column-push";
@import "common/functions/static-column-size";

// media queries mixin
@import "include-media/dist/_include-media";
// mixins
@import "common/mixins/aspect-ratio";
@import "common/mixins/min-aspect-ratio";
@import "common/mixins/button";
@import "common/mixins/clearfix";
@import "common/mixins/ease";
@import "common/mixins/fade";
@import "common/mixins/store-values-for-js";

@import "common/mixins/dynamic-columns";
@import "common/mixins/float-columns";
@import "common/mixins/static-columns";

@import "common/mixins/media-map";
@import "common/mixins/object-fit";
@import "common/mixins/on-hover";
@import "common/mixins/selector-or-media";
@import "common/mixins/visually-hidden";

// ubf-mixins
@import "ubf/mixins/config";

// normalize, reset, helpers
@import "normalize-css/normalize.css";
@import "common/generic/h5bp";
@import "common/generic/reset";

// animations
// @import "ubf-print/animations/loader";

// blocks
// @import "ubf-print/blocks/anchor";

// somehow this could help the page-break-* style rule to work in the browsers
* {
	position: relative;
}

// TODO: to import eventually
// @import "ubf-print/blocks/accordion-controller";

// @import "ubf-print/blocks/calendar";
// @import "ubf-print/blocks/detailed-list";
// @import "ubf-print/blocks/search-results-splitted";


@import "ubf-print/blocks/accordion";
@import "ubf-print/blocks/address";
@import "ubf-print/blocks/body";
@import "ubf-print/blocks/button";
@import "ubf-print/blocks/content-block";
@import "ubf-print/blocks/day-event";
@import "ubf-print/blocks/event";
@import "ubf-print/blocks/events";
@import "ubf-print/blocks/events-details";
@import "ubf-print/blocks/excerpt";
@import "ubf-print/blocks/excerpts";
@import "ubf-print/blocks/field";
@import "ubf-print/blocks/form";
@import "ubf-print/blocks/gallery";
@import "ubf-print/blocks/gallery-item";
@import "ubf-print/blocks/header";
@import "ubf-print/blocks/highlights";
@import "ubf-print/blocks/html";
@import "ubf-print/blocks/page";
@import "ubf-print/blocks/pagination";
@import "ubf-print/blocks/picture";
@import "ubf-print/blocks/preview";
@import "ubf-print/blocks/previews";
@import "ubf-print/blocks/scroll-to-top";
@import "ubf-print/blocks/search-results";
@import "ubf-print/blocks/section";
@import "ubf-print/blocks/sidebar";
@import "ubf-print/blocks/slide";
@import "ubf-print/blocks/slideshow";
@import "ubf-print/blocks/social";
@import "ubf-print/blocks/tabs";
@import "ubf-print/blocks/text";
@import "ubf-print/blocks/text-box";
@import "ubf-print/blocks/ugc";
@import "ubf-print/blocks/video";
