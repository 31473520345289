.ubf-picture {
	display: block;
	page-break-inside: avoid;

	&.ubf-preview__picture {
		margin-bottom: $base2;
		position: relative;
	}

	.ubf-section--cover & {
		display: none;
	}


	&__caption {
		@include ubf-font(caption);
		background-color: $colorBgBase;
		// fix a rendering bug when some letters appears cut on the right
		padding-right: 1px;
		padding-top: $baseHalf;
		page-break-inside: avoid;
		text-align: right;

		.ubf-preview__picture & {
			height: $base2;
			left: 0;
			margin: 0;
			position: absolute;
			top: 100%;
			width: 100%;

		}
	}


	&__img {
		max-width: 100%;
		position: relative;
		page-break-inside: avoid;
		align-self: flex-start;

		.ubf-address & {
			@include objectFit(cover);
			height: 100%;
			width: 100%;
		}

		.ubf-preview & {
			width: 100%;
		}


		.ubf-preview:not(.ubf-preview--noCrop) & {
			// @include objectFit(cover);
			// left: 0;
			// height: 100%;
			// position: absolute;
			// top: 0;
		}

		.ubf-galleryItem & {
			@include objectFit(cover, 50% 50%);
			height: 100%;
			width: 100%;
		}

		.ubf-slideshow & {
			@include objectFit(contain, 50% 0%);
			height: 100%;
			width: 100%;
		}
	}
}
