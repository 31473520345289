.ubf-event {
	&__content {
		.ubf-event__title + & {
			margin-top: $base2;

			.ubf-sidebar & {
				margin-top: $base;
			}
		}
	}


	&__title {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include ubf-font(h);
		}
	}
}
