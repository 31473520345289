.ubf-gallery {
	position: relative;


	&__item {
		width: dynamicColumnSize(1, 3, $gridGutter);
	}


	&__items {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $gridGutter * 2, $hSpacePos: right);
		margin-bottom: $base2;
	}


	&__slideshow {
		display: none;
	}
}
