.ubf-contentBlock {
	@mixin canBeNestedInSidebar() {
		.ubf-body .ubf-sidebar & {
			width: dynamicColumnSize(1, 1, $gridGutter);
		}
	}

	width: dynamicColumnSize(1, 1, $gridGutter);


	&--accordion {
		// no custom version until now
		page-break-after: avoid;
		page-break-inside: avoid;
	}


	&--address {
		@include canBeNestedInSidebar();
		width: dynamicColumnSize(3, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(3, 9, $gridGutter);
		}
	}


	&--calendar {
		display: none;
	}


	&--events {
		@include canBeNestedInSidebar();

		width: dynamicColumnSize(6, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(6, 9, $gridGutter);
		}

		& + & {
			margin-top: $base8;

			.ubf-sidebar & {
				margin-top: $base2;
			}
		}
	}


	&--eventsDetails {
		width: dynamicColumnSize(1, 1, $gridGutter);
	}


	&--highlights {
		// no custom version until now
	}


	&--gallery {
		// no custom version until now
	}


	&--pagination {
		width: dynamicColumnSize(6, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(6, 9, $gridGutter);
		}

		* + & {
			margin-top: $base4;
		}
	}


	&--previews {
		// no custom version until now
	}


	&--searchResults {
		width: dynamicColumnSize(6, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(6, 9, $gridGutter);
		}
	}


	&--sidebar {
		width: dynamicColumnSize(3, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(3, 9, $gridGutter);
		}
	}


	&--slideshow {
		.ubf-section--cover & {
			width: 100%;
		}
	}


	&--tabs {
		// no custom version until now
	}


	&--text {
		@include canBeNestedInSidebar();

		width: dynamicColumnSize(12, 12, $gridGutter);

	}


	&--textBox {
		@include canBeNestedInSidebar();

		.ubf-section:not(.ubf-section--cover) .ubf-section__content > & {
			@include minAspectRatio(1);
			width: dynamicColumnSize(5, 12, $gridGutter);
		}
	}


	&--video {
		width: dynamicColumnSize(9, 12, $gridGutter);

		.ubf-body--generic &,
		.ubf-body--internal & {
			width: dynamicColumnSize(1, 1, $gridGutter);
		}

		&.ubf-contentBlock--videoSmall {
			width: dynamicColumnSize(6, 12, $gridGutter);

			.ubf-body--generic &,
			.ubf-body--internal & {
				width: dynamicColumnSize(6, 9, $gridGutter);
			}
		}
	}

	&__eventsDetails {
		min-height: 100%;
	}


	&__slideshow {
	}


	&__title {
		@include ubf-font(h3);
		margin-bottom: $base2;
		position: relative;
		page-break-after: avoid;
		page-break-inside: avoid;


		.ubf-contentBlock + .ubf-contentBlock & {
			margin-top: $base4;

			.ubf-sidebar & {
				margin-top: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
