.ubf-body {
	@include ubf-font(base);
	color: $colorBase;
	font-family: $fontFamilyMain;
	hyphens: auto;
	position: relative;
	// text-align: justify;
	overflow-x: hidden;
	overflow-y: scroll;


	&__footer {
		display: none;
	}

	&__mainMenu {
		display: none;
	}


	&__modal {
		display: none;
	}

	&--ekvv {
		background-color: $colorEkvvBgBase;
	}
}
