.ubf-excerpts {
	&__excerpt {
		& + & {
			margin-top: $base2;
		}
	}


	&__moreLink {
		@include ubf-link();
		display: inline-block;

		* + & {
			margin-top: $base2;
		}
	}
}
