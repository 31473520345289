// @import "../../ubf/config/sizes";

@use "sass:math";

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;

$baseHalf: $base * 0.5;

$logoSize: $base * 4.5;
$logoMargin: $base * 1.5;

$campaignLogoHeight: 1.3rem;
$campaignLogoLeftPosition: 16rem;

// difference in the height between the name and the logo
$nameLogoRatio: math.div(42, 45);
$facultyLogoRatio: math.div(47, 45);

$boxBorderSize: 2px;
$tableCellBorderSize: 1px;
$tableHeaderBorderSize: 2px;

$headerHeightSmall: $base * 5.5;
$menuHeight: $base8;

$pageTopPadding: $base2;


$maxGalleryItemCount: 20;
$galleryNavigationIconSize: $base * 1.5;
$gallerySlidesRatio: 3 * 0.5;

$videoRatio: math.div(16, 9);

$contentElementSpace: 1em;

$bildBoxSpace: 2rem;

$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;


$sectionVerticalSpace: $base6;
$sectionHorizontalSpace: $base10;
$gridGutter: $base2;


$ekvvAssignmentPlanMinWidth: 768px;

$weekCalendarPxPerMinute: 1px;
$weekCalendarCollisionMargin: 2px;
$cardItemsMargin: 2px;

$sizeVariants: (
	logoGeometry: (
		height: $logoSize,
		left: 0,
		width: $logoSize,
		top: $base
	),
	logoBarGeometry: (
		height: $base,
		left: 0,
		width: $logoSize,
		top: $base + $logoSize + $logoMargin
	),
	nameGeometry: (
		height: $logoSize * $nameLogoRatio,
		left: $logoMargin + $logoSize,
		width: auto,
		top: $base + $logoSize - $logoSize * $nameLogoRatio,
		// fix the alignment with the logo
		margin-top: 1px
	),
	facultyGeometry: (
		height: $logoSize * $facultyLogoRatio,
		left: $logoMargin + $logoSize,
		width: auto,
		top: $base + $logoSize + $logoMargin,
		// fix the alignment with the rectangle bar
		margin-top: -2px
	),

	campaignGeometry: (
		left: $campaignLogoLeftPosition + $base2,
		width: auto,
		top: $base + $logoSize - $logoSize * $nameLogoRatio + $logoSize * $nameLogoRatio - $campaignLogoHeight,
		height: $campaignLogoHeight,

	),

);
