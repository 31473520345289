.ubf-textBox {
	@include ubf-highlightColor(border-color);
	border-width: $boxBorderSize;
	border-style: solid;
	page-break-inside: avoid;
	position: relative;

	&__body {
		* + & {
			margin-top: $base2;
		}
	}


	&__content {
		display: block;
		padding: $base2;
	}


	&__title {
		@include ubf-font(h2);

		.ubf-body--home .ubf-section--highlights & {
			@include ubf-font(h1);
		}

		.ubf-section--cover & {
			@include ubf-font(h2);
		}
	}
}
