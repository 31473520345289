.ubf-tabs {
	@include ubf-highlightColor(border-color);
	position: relative;
	align-items: stretch;
	align-content: flex-start;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	overflow: hidden;
	padding-bottom: $base2;
	border-style: solid;
	border-width: 0 0 $boxBorderSize 0;


	&__content {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $gridGutter, $hSpacePos: left, $vSpacePos: bottom);
		@include ease(opacity, $duration4);
		left: 0;
		position: relative;
		right: 0;
		top: 0;
		padding: 0;

		.ubf-tabs__contentWrapper:not(.ubf-js-selected) & {
			display: none;
		}
	}

	&__contentWrapper {
		border: 0;
		margin: 0;
		order: 10;
		position: relative;
		overflow: visible;
		transition: none;
		width: 100%;
		z-index: 1;

		html.js &.ubf-js-selected {
			order: 20;
			z-index: 20;
		}
	}


	&__tab {
		margin-bottom: $base2;
		margin-right: $gridGutter;
		order: 1;
		width: staticColumnSize(2, 9, $gridGutter);
	}


	&__tabIcon {
		display: none;
	}


	&__tabLabel {
		display: inline-block;
	}


	&__tabLink {
		align-items: center;
		background-color: $colorBgTabs;
		border-style: solid;
		border-color: $colorBgTabs;
		border-width: $boxBorderSize 0;
		color: $colorTabs;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		padding: $baseHalf $base;
		position: relative;
		text-decoration: none;


		&.ubf-js-toggled {
			@include ubf-highlightColor(border-color);
			background-color: transparent;
		}

		&.ubf-js-selected {
			@include ubf-highlightColor(border-top-color);
			border-bottom-color: transparent;
			background-color: transparent;
		}
	}
}
