.ubf-field {
	@include ubf-font(base);

	&__button {
		.ubf-field--search .ubf-field__input--text + & {
			margin-left: $base2;
		}
	}

	&__content {
		margin-left: -$base3;

		.ubf-field--group > & {
			margin-left: 0;
		}
	}


	&__error {
		@include ubf-font(fieldError);
		color: $colorError;
	}


	&__errors {
		margin-left: $base3;
		margin-top: $baseHalf;
	}


	&__field {
		width: dynamicColumnSize(1, 1, $gridGutter);

		.ubf-field__fields--horizontal > & {
			display: inline-block;
			width: auto;
			margin-left: $base3;
			margin-top: 0;
		}

		.ubf-field--checkboxes &,
		.ubf-field--radios & {
			margin-top: 0;
		}
	}



	&__fields {
		@include dynamicColumns($hSpace: $gridGutter, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);//, $childrenSelector: '.ubf-form__field');
		position: relative;

		.ubf-field--checkboxes &,
		.ubf-field--radios & {
			margin-top: 0;
		}

		&--horizontal {
			font-size: 0;
			display: block;
			margin-top: 0;
			margin-left: -$base3;
		}
	}


	&__input {
		@include ubf-font(base);
		background-color: $colorBgInput;
		color: $colorInput;

		&:focus {
			@include ubf-highlightColor(outline-color);
			outline-style: solid;
			outline-width: $inputBorderSize;
		}

		&--password,
		&--text {
			border: $inputBorderSize solid $colorInputBorder;
			padding: $inputPadding;
			width: 100%;

			.ubf-field--invalid & {
				border-color: $colorError;
			}

			.ubf-form__field--full & {
				max-width: staticColumnSize(6, 8, $base2);
			}

			.ubf-field__label--radioText & {
				padding: $inputNestedPadding $inputPadding;
			}

			.ubf-field--search & {
				width: auto;
				flex-grow: 1;
			}
		}

		&--textarea {
			border: $inputBorderSize solid $colorInputBorder;
			min-height: $base2 + getFontSize(base) * getLineHeight(base) * 4;
			padding: $base;
			width: 100%;

			.ubf-form__field--full & {
				max-width: staticColumnSize(6, 8, $base2);
			}

			.ubf-field--invalid & {
				border-color: $colorError;
			}
		}

		&--select {
			background-color: transparent;
			border: $inputBorderSize solid $colorInputBorder;
			// needed because on OSX to reset the appearance is not enough
			border-radius: 0;
			min-width: $buttonMinWidth;
			padding: $inputPadding $base2 $inputPadding $inputPadding;
			position: relative;
			width: 100%;
			z-index: 2;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			.ubf-field--invalid & {
				border-color: $colorError;
			}
		}

		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}
	}


	&__inputWrapper {
		margin-left: $base3;
		position: relative;

		.ubf-field--horizontal & {
			display: inline-block;
		}

		.ubf-form__field--full.ubf-field--select & {
			max-width: staticColumnSize(6, 8, $base2, -$base2);
		}

		.ubf-field--search & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			max-width: staticColumnSize(6, 8, $base2, -$base2);
		}

		.ubf-field--radioText & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
		}
	}


	&__label {
		@mixin replaceInput($circle: true) {
			&::before {
				border: $inputBorderSize solid $colorInputBorder;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize;
				left: -$base3;
				position: absolute;
				top: $base;
				vertical-align: middle;
				width: $radioOuterSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				@include ubf-highlightColor(background-color);
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioInnerSize;
				left: -$base3 + ($radioOuterSize - $radioInnerSize) * 0.5;
				opacity: 0;
				position: absolute;
				top: $base + ($radioOuterSize - $radioInnerSize) * 0.5;
				vertical-align: middle;
				width: $radioInnerSize;

				input:checked + & {
					opacity: 1;
				}
			}

			input:focus + & {
				@include ubf-highlightColor(outline-color);
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		@include ubf-font(base);
		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		color: $colorInput;
		display: block;
		margin-left: $base3;
		padding: $inputPadding 0;
		position: relative;

		.ubf-form--login & {
			padding-top: 0;
		}

		.ubf-field--group > .ubf-field__content > & {
			margin-left: 0;
		}

		.ubf-field--password,
		.ubf-field--text & {
			display: block;
		}

		&--checkbox {
			@include replaceInput($circle: false);
		}

		&--radio {
			@include replaceInput($circle: true);

			.ubf-field--radioText & {
				bottom: 0;
				flex-grow: 0;
				left: 0;// $base3;
				padding-right: 0;
				padding-left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}
	}


	&__labelValue {
		.ubf-field--required > .ubf-field__content > .ubf-field__label &::after,
		.ubf-field--required > .ubf-field__content > .ubf-field__inputWrapper > .ubf-field__label &::after {
			content: '*';
		}
	}


	&__selectIcon {
		color: $colorInput;
		position: absolute;
		right: $baseHalf;
		top: 50%;
		height: $base;
		width: $base;
		text-align: center;
		transform: translateY(-50%) rotate(90deg);
		z-index: 1;


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}
}
