.ubf-searchResults {
	&__item {
		& + & {
			@include ubf-highlightColor(border-top-color);
			border-top-style: solid;
			border-top-width: $boxBorderSize;
			margin-top: $base2;
			padding-top: $base2;
		}
	}


	&__itemInfo {
		color: $colorSecondaryInfo;
	}
}
