.ubf-pagination {
	&__item {
		& + & {
			margin-left: $base2;
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
